$primaryColour: #095A70;
$secondaryColour: #683F8C;

//@font-face {
//  font-family: "brandon-grotesque-bold";
//  src: url("/fonts/BrandonGrotesque-Bold.otf");
//}
//
//@font-face {
//  font-family: "brandon-grotesque-regular";
//  src: url("/fonts/BrandonGrotesque-Regular.otf");
//}
//
//@font-face {
//  font-family: "brandon-grotesque-medium";
//  src: url("/fonts/BrandonGrotesque-Medium.otf");
//}
//
//@font-face {
//  font-family: "brandon-grotesque-black";
//  src: url("/fonts/BrandonGrotesque-Black.otf");
//  font-weight: 100;
//}

.link-card {
  cursor: pointer;
}

.carousel-item-container {
  border: 1px solid $secondaryColour;
  margin-bottom: 10px;
  height: 100%;
}

//.parallaxx.parallaxXl {
//  background-position: unset;
//  background-size: cover;
//  min-height: 450px !important;
//  max-height: 475px !important;
//  max-width: 100%;
//
//  &::before {
//    background-image: url("../../../public/img/arrow.png") !important;
//    max-width: 1140px; // Keep larger arrow background image fully in view.
//  }
//}

.school-tex li::before {
  content: "• ";
  color: $secondaryColour;
  font-size: 22px;
}

.school-tex{
  list-style: none;
  padding-left: 0 !important;
}

.school-tex li{
 font-size: 16px;
}

.list-item{
  position: relative;
}

.padding-right-zero{
  padding-right: 0 !important;
}

.list-item .active{
  width: 100%;
  height: 3px;
  background: $secondaryColour;
  position: absolute;
  bottom: -26px;
}

.small-icons {
  font-size: 1px;
}

.slick-list {
  padding-bottom: 30px;
}
.slick-dots {
  bottom: -30px !important;
}
.slick-dots li {
  margin: 0;
  width: 15px;
  height: 15px;
}
.slick-dots li button::before {
  border-radius: 100%;
  border: 1px solid #095a70;
  top: 0px;
  width: 5px;
  height: 5px;
}
.slick-dots li.slick-active button:before {
  background-color: #095a70;
  width:5px;
  height: 5px;
}
.search_section .MuiFormControl-fullWidth label,
.search_section .MuiFormControl-fullWidth input {
  //font-family: var(--font-brandon-grotesque);
}
.search_section .MuiFormControl-fullWidth input::-webkit-input-placeholder {
  /* Edge */
  //font-family: var(--font-brandon-grotesque);
}
.search_section .MuiFormControl-fullWidth input::placeholder {
  //font-family: var(--font-brandon-grotesque);
}

.makeStyles-formControl-94 {
  margin: 0 0 17px 0;
  position: relative;
  padding-top: 12px;
  margin-bottom: 0px !important;
}

button.stdBtn {
  text-transform: inherit !important;
  //font-family: var(--font-brandon-grotesque) !important;
  font-weight: 900;
  font-size: 19px !important;
  border-radius: 6px;
  padding-top: 8px;
  padding-bottom: 8px;
  box-shadow: none;
}

.MuiInput-formControl:after {
  border-color: $secondaryColour !important;
}

.MuiInput-formControl:before {
  border-bottom: 0.1rem solid #fff !important;
}

//.parallaxx {
//  background-repeat: no-repeat;
//  max-width: 100%;
//  margin: auto !important;
//  background-size: contain;
//  max-height: 270px;
//  background-position: center top;
//}

.parallaxx .title {
  margin-top: 30px;
  text-shadow: 0px 0px 20px rgba(0, 0, 0, 0.3);
}

.parallaxx::before {
  left: 50% !important;
  transform: translateX(-50%) !important;
  max-width: 1500px;
}

p,
li {
  //font-family: var(--font-brandon-grotesque) !important;
  font-weight: normal;
}
.list li {
  text-align: left;
}
*[role="tooltip"] {
  transform: translate3d(0px, 49px, 0px) !important;
}

.topBar {
  background: $primaryColour;
  width: 100%;
  display:flex;
  align-items: center;
  height: 50px;
}

header {
  padding-top: 40px !important;
}
.topBar p {
  color: #fff;
  font-size: 20px;
  padding: 0 0 0 20px;
  margin-bottom: 0;;
}

.topBar a {
  color: #fff;
  //font-family: "var(--font-brandon-grotesque)";
  font-weight: 500;
  text-decoration: none;
}
.topBar svg {
  margin: 0px 9px;
  color: #fff;
}

.fullwidth {
  max-width: 100% !important;
  background-size: cover !important;
}

.parallaxx::before {
  background: rgba(0, 0, 0, 0) !important;
}

.parallaxx::after {
  background: rgba(0, 0, 0, 0.1) !important;
}

@media only screen and (max-width: 1024px) {
  ///*Small smartphones [325px -> 425px]*/
  //.parallaxx {
  //  background-repeat: no-repeat;
  //  // todo soon this needs changing as images with text etc. can get cut off on mobile
  //  background-size: cover !important;
  //  max-height: 530px !important;
  //  background-position: center top;
  //  min-height: auto !important;
  //
  //  &.parallaxx-compact {
  //      max-height: 240px !important;
  //  }
  //}

  .slick-current{
    padding: 0 10px;
  }
}

@media only screen and (max-width: 425px) {
  li.MuiListItem-root::after {
    display: none;
  }
}

@media only screen and (max-width: 768px) {
  .list-item .active{
    display: none !important;
  }
}

header.newheader {
  padding-bottom: 0;
  max-width: 100% !important;
  margin: auto !important;
  display: block;
  position: relative;
  padding: 0 !important;
}

.noTopMargin > * {
  margin-top: 0 !important;
}

.noTopPadding > * {
  padding-top: 0 !important;
}

.noBottomPadding > * {
  margin-bottom: 0 !important;
}

.noBottomPadding > * {
  padding-bottom: 0 !important;
}

span.pseudoPlaceholder,
span.pseudoPlaceholderWhat {
  position: absolute;
  font-size: 16px;
  //font-family: "var(--font-brandon-grotesque)";
  font-weight: 700;
  padding-left: 9px;
  margin-bottom: -0.5px;
}

.whereField > input::placeholder, .whatField > input::placeholder {
  padding-left: 20px;
}

.coooler > * {
  float: left !important;
  display: flex !important;
  position: relative !important;
  flex-direction: row !important;
  flex-wrap: nowrap !important;
  align-content: stretch !important;
  justify-content: flex-start !important;
  align-items: baseline !important;
}

.coooler {
  padding: 0px !important;
  height: auto !important;
  margin: 0 !important;
}

.widthNotAuto {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.makeStyles-main-5,
.mainsitecontainer {
  max-width: 1140px;
  margin: auto;
}

.center-data {
  display: flex;
  justify-content: center;
}

html {
  overflow-x: hidden !important;
}

.body {
  background-color: #fff !important;
}

.footer {
  max-width: 100% !important;
}
/*Responsiveness*/

@media only screen and (min-width: 960px) {
  /*Devices which are 960px and above*/
  .five_grid {
    min-width: 20%;
  }

  .parallaxx::before {
    content: "";
    background-image: url("../../../public/img/arrow_small.png") !important;
    background-size: contain !important;
    background-position: calc(50% - 25px) calc(100% - 30px) !important;
    background-repeat: no-repeat !important;
  }
}
@media only screen and (max-width: 960px) {
  /*Devices which are 960px and below*/
  .parallaxx h1 {
    font-size: 53px;
    margin-top: 115px;
  }
  h2 {
    line-height: 1 !important;
  }
  .welcomeImg {
    display: none !important;
  }
  .bgImg {
    height: 230px !important;
  }
  .socialIcons svg {
    display: none !important;
  }
}

@media only screen and (max-width: 600px) {
  /*Big smartphones [426px -> 600px]*/

  .home .parallaxx {
  	background-size: cover !important;
  	background-image: url(/img/landing-mobile.jpg) !important;
  }
  .home .parallaxx .title {
  	text-align: center;
  	margin-top: 25px;
  }
  .home .parallaxx {
  	min-height: 493px !important;
  }
  .colored_bgg {
  	padding-top: 20px !important;
  	padding-bottom: 30px !important;
  	min-height: 0px !important;
  }
  .colored_bgg h1 {
  	font-size: 52px !important;
  }

  /*Carousel Slider*/

  .aassaaasasa {
    padding-top: 0 !important;
  }
  .slick-track .jobcard {
    padding-left: 10px;
    padding-right: 10px;
  }
  .slick-track .jobBox {
    border: 1px solid #06aeab;
    padding-left: 0;
    padding-right: 0;
  }
  .slick-track .jobBox * {
    border: none;
    letter-spacing: 0px;
    line-height: 1;
  }
  .slick-track .jobcard h4~div, .slick-track .jobcard h4 button {
    display: none;
  }

  .aassaaasasa~div {
    padding-bottom: 0;
  }
  .aassaaasasa~div {
    padding-bottom: 0;
  }

  .sidebar {
    margin-bottom: 2rem;
  }

  /* Got You Section */

  .gotyou {
    margin-top: 15px;
    margin-bottom: 0px;
    min-height: auto !important;
  }
  .gotyou p {
    font-weight: 100 !important;
    padding-left: 5px !important;
    padding-right: 5px !important;
  }
  .aassaaasasa~div {
    padding-bottom: 0;
  }
  html .gotyou {
    margin-top: 15px !important;
    margin-bottom: 0px !important;
    min-height: auto !important;
  }
  .gotyou p {
    font-weight: 100 !important;
    padding-left: 5px !important;
    padding-right: 5px !important;
  }

  .needAdvice {
    padding-top: 0px !important;
  }

  .needAdvice h2 {
    margin: 0;
  }
  .needAdvice p {
    margin: 0;
  }
  /*Small smartphones [325px -> 425px]*/
  .topBar svg {
    display: none;
  }
  .topBar p {
    font-size: 14px;
    line-height: 1;
  }
  //.parallaxx {
  //  background-repeat: no-repeat;
  //  background-size: cover !important;
  //  max-height: 530px !important;
  //  background-position: center top !important;
  //  min-height: auto !important;
  //
  //  &.parallaxx-compact {
  //    max-height: 240px !important;
  //  }
  //}

  .parallaxx::before {
    background-image: none !important;
  }

  .home .parallaxx {
    min-height: 600px !important;
  }

  .sec_Img, .subSec_Img {
    display: none;
  }

  .sec_Img, .subSec_Img {
    display: none;
  }

  .noTopPadding {
    padding-top: 0 !important;
  }
  .getonboard {
    padding: 0 !important;
  }
  .getonboard h2 {
    margin-top: 0 !important;
  }
  .getonboard br {
    display: none !important;
  }
  .workwithlogos>div {
    padding: 0 7px;
  }
  .workwithtext>div {
    margin-bottom: 0;
  }
  .workwithlogos {
    margin-bottom: 25px;
  }
}

/*Responsiveness*/

@media only screen and (max-width: 375px) {
  .slick-track .jobBox>div:first-child {
  	display: block;
  	float: left;
  	position: relative;
  	height: 100%;
  	background: #eaf6fe;
  }

  .slick-track .jobBox>div>img {
  	top: calc(50% + 0px);
  	position: absolute;
  	transform: translateY(-50%);
  }
}

.triangle-container {
  position:relative;
}

.corner {
  position: absolute;
  z-index: 1;
  width: 0;
  height: 0;
  border-top: 52px solid $secondaryColour;
  left: -0.5px;
  top: 0px;
  border-bottom: 54px solid transparent;
  border-right: 54px solid transparent;
}

.corner span {
  position:absolute;
  top: 35px;
  width: 100px;
  left: 5px;
  text-align: center;
  font-size: 16px;
  font-family: arial;
  transform: rotate(-45deg);
  display:block;
}

.pin-icon{
  fill: white;
  width: 51px;
  z-index: 1;
  position: absolute;
  top: -9px;
  left: -10px;
}


.description{
    color: #000;
    //font-family: var(--font-brandon-grotesque);
    font-size: 16px;
    font-weight: 100 !important;
    text-align: left;
    line-height: 1.15;
}

.whatField::before, .whereField::before{
  display: none !important;
}

.whatField input, .whereField input{
  border: 0.75px solid #26596E;
}

button{
  border-radius: 6px !important;
}

.removeArrow.parallaxx::before {
  background-image: none !important;
}

.whatField-1 .pseudoPlaceholderWhat{
  margin-bottom: 1.5px !important;
}

.MuiSnackbarContent-message {
  padding-left: 7px !important; // "Tightened" vs. 15px default as requested. Makes padding look more even if no icon.
}

// Pulled from frontend/components/Grid/GridItem.js into css to avoid specificity issues
.MuiGrid-styledGridItem {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
  flex-basis: auto;
}

// Pulled from frontend/components/Grid/GridContainer.js into css to avoid specificity issues
.MuiGrid-styledGridContainer {
  margin-right: -15px;
  margin-left: -15px;
  width: auto;
}

// Default WYSIWYG styling:
.user-content {
  font-size: 17px;
  // Clearfix
  &::after {
      content: "";
      display: table;
      clear: both;
  }

  h1, h2, h3, h4, h5, h6 {
    //font-family: var(--font-brandon-grotesque) !important;
    color: #095A70;
    font-weight: 900;
  }

  h2 {
    font-size: 1.9rem;
  }

  strong {
    font-weight: bold;
  }

  table, tr, td {
    border: none;
  }

  img {
    max-width: 100%;
    height: 100%;
  }
}